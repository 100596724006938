import React, { useState } from "react"
import CSS from "csstype"

const StickMan = () => {
  const [strokeWidth, setStrokeWidth] = useState(4)
  const [visibilityDict, setVisibilityDic] = useState({
    gallowBase: false,
    gallowBeam1: false,
    gallowBeam2: false,
    rope: false,
    head: false,
    torso: false,
    leftArm: false,
    rightArm: false,
    leftLeg: false,
    rightLeg: false,
    eyeDeadL: false,
    eyeDeadR: false,
  })

  const strokeCss: CSS.Properties = {
    stroke: "black",
    strokeWidth: `strokeWidth`,
  }

  const visible: CSS.Properties = {
    visibility: "hidden",
  }

  const changeVisibility = () => {
    return ""
  }

  const drawStickman = (step: number) => {}

  return (
    <svg viewBox="0 0 400 400" style={strokeCss && visible}>
      <g id="body">
        <g id="head">
          <circle cx="200" cy="80" r="20" fill="white" />
          <g id="eyes-alive" className="hide">
            <circle cx="193" cy="80" r="4" />
            <circle cx="207" cy="80" r="4" />
          </g>
          <g id="eyes-dead" className="show">
            <g id="eye-dead-l">
              <line x1="190" y1="78" x2="196" y2="84" />
              <line x1="190" y1="84" x2="196" y2="78" />
            </g>
            <g id="eye-dead-r">
              <line x1="204" y1="78" x2="210" y2="84" />
              <line x1="204" y1="84" x2="210" y2="78" />
            </g>
          </g>
        </g>
        <line id="torso" x1="200" y1="100" x2="200" y2="150" />
        <line id="arm-l" x1="200" y1="120" x2="170" y2="140" />
        <line id="arm-r" x1="200" y1="120" x2="230" y2="140" />
        <line id="leg-l" x1="200" y1="150" x2="180" y2="190" />
        <line id="leg-r" x1="200" y1="150" x2="220" y2="190" />
      </g>
      <line id="gallow-base" x1="50" y1="250" x2="150" y2="250" />
      <line id="gallow-beam-1" x1="100" y1="250" x2="102" y2="20" />
      <line id="gallow-beam-2" x1="100" y1="20" x2="200" y2="20" />
      <line id="rope" x1="200" y1="18" x2="200" y2="60" />
    </svg>
  )
}

export default StickMan
