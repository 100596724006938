import React from 'react'
import { useState } from "react";
import StickMan from './StickMan';

const AhorcadoGame = () => {
	//hangman game
	const [word, setWord] = useState('');
	const [guessedLetters, setGuessedLetters] = useState([]);
	const [wrongLetters, setWrongLetters] = useState([]);
	const [wrongGuesses, setWrongGuesses] = useState(0);
	


	return (
		<>
		holi<br />
			<StickMan />

		</>
	)
}

export default AhorcadoGame