import React from 'react'
import AhorcadoGame from "../components/ahorcado/AhorcadoGame"
import Layout from '../components/layout'

const Ahorcado = () => {
	return(
		<Layout>
			<AhorcadoGame />
		</Layout>

	)
}

export default Ahorcado